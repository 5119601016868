<template>
    <OMediaQueryProvider v-slot="{ isTouch }">
        <div :class="className" :style="styleName" v-if="session.personId && filterObject.combinedFilterString">
          
                <div class="btn-group dropup" v-if="isTouch" >
                    <button :disabled="!filterObject.combinedFilterString || !filterObject.activeFilter || !filterObject.activeFilterHasChanges || isSaving.currentFilter || (filterObject.activeFilter && !filterObject.activeFilter.Mine)" 
                        @click="saveFilter" 
                        :title="getDisabledSaveTitle(filterObject)"
                        :class="saveClass">
                        <div v-if="isSaving.currentFilter" class="spinner-border spinner-border-sm" role="status"></div>
                        {{$t('Save')}}
                    </button>

                    <button type="button"
                        class="dropdown-toggle dropdown-toggle-split"
                        :class="saveClass"
                        style="min-width:35px;"
                        data-bs-toggle="dropdown"
                        aria-expanded="false">
                    </button>
                
                    <ul class="dropdown-menu dropdown-menu-start bg-transparent border-0">
                        <li @click="saveFilterAs" :title="$t('Save as / rename current filter')" :class="saveAsClass">{{$t('Save As New')}}</li>
                    </ul>
                </div>
 
                <div v-if="!isTouch">
                    <button :disabled="!filterObject.combinedFilterString || !filterObject.activeFilter || !filterObject.activeFilterHasChanges || isSaving.currentFilter || (filterObject.activeFilter && !filterObject.activeFilter.Mine)" 
                        @click="saveFilter" 
                        :title="getDisabledSaveTitle(filterObject)"
                        :class="saveClass">
                        <div v-if="isSaving.currentFilter" class="spinner-border spinner-border-sm" role="status"></div>
                        {{$t('Save')}}
                    </button>
                
                    <button :disabled="!filterObject.combinedFilterString || isSaving.currentFilter"  @click="saveFilterAs" 
                            :title="getDisabledSaveAsTitle(filterObject)" :class="saveAsClass">{{$t('Save As')}}</button>
                    <div class="dropdown" style="display: contents;">
                        <button :disabled ="!filterObject.combinedFilterString || !filterObject.activeFilter || (filterObject.activeFilter && !filterObject.activeFilter.Mine)"
                            :title="getDisabledShareWithTitle(filterObject)" :class="saveAsClass" class="dropdown-toggle" data-bs-toggle="dropdown">
                            {{$t('Share With')}}
                        </button>
                        <ul class="dropdown-menu">
                            <li><button @click="shareWith" class="dropdown-item" type="button">{{ $t('Persons')}}</button></li>
                            <li><button @click="() => $refs.shareWithOrgUnitsModal.show()" class="dropdown-item" type="button">{{ $t('Org Units') }}</button></li>
                        </ul>
                    </div>
                    <!-- <button :disabled ="!filterObject.filterString || !filterObject.activeFilter || (filterObject.activeFilter && !filterObject.activeFilter.Mine)" -->
                            <!-- @click="shareWith" :title="getDisabledShareWithTitle(filterObject)" :class="saveAsClass">{{$t('Share With')}} -->
                    <!-- </button> -->
                </div>
      
            
          <!--  <button :disabled="!filterObject.activeFilter"  @click="saveNewFilter" :title="getDisabledSaveTitle(filterObject)" :class="saveClass">{{$t('Save')}}</button>-->
        </div>

        <ODialog v-model:show="showSaveAsDialog" backdrop>
            <template #header="{ title }">
                <h5 class="modal-title me-2">{{$t(modal.title)}}</h5>
                <button type="button" class="btn-close ms-auto" @click="() => showSaveAsDialog=false" aria-label="Close"></button>
            </template>

            <div class="o365-dialog-body">
                <label>{{$t('Filter Name')}}</label>
                <input class="form-control form-control-sm" v-model="modal.Name" @keyup.enter="doSaveNewFilter" ref="newInput">
            </div>

            <div class="o365-dialog-footer">
                <button type="button" class="btn btn-secondary" @click="() => showSaveAsDialog=false">{{$t('Close')}}</button>
                <button type="button" :disabled="!modal.Name" @click="doSaveNewFilter" class="btn btn-primary">{{$t('Save')}}</button>
            </div>
        </ODialog>
        <OModal ref="shareWithModal" >
            <div class="modal-dialog modal-lg"  >
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title fs-5">{{$t('Share with Persons')}}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    <ODataGrid :dataObject = "dsSharedWith" hideGridMenu showNewRecordsPanel>
                            <OColumn name="PersonName" editable width="400px">
                            <template #editor="{row}">
                                    <OPersonsLookup v-model="row.PersonName" 
                                                    :bind="(sel)=>{row.Person_ID = sel.ID;row.PersonName = sel.Name }">
                                    </OPersonsLookup>
                                </template>
                            </OColumn>
                    </ODataGrid>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{$t('Close')}}</button>                    
                    </div>
                </div>
            </div>
        </OModal>
    <ShareWithOrgUnitsModal ref="shareWithOrgUnitsModal" @hide="dsFilters.load()" :filterItem="filterObject.activeFilter" />
    </OMediaQueryProvider>
</template>

<script setup>
    import { ref, nextTick } from 'vue';
    import { OModal, OMediaQueryProvider, ODialog } from 'o365-ui-components';
    import { getUserSession } from 'o365-modules';
    import { OPersonsLookup } from 'o365-system-lookups';
    import { filterStore } from 'o365-filterobject';
    import ShareWithOrgUnitsModal from './components.Filter.ShareWithOrgUnits.vue';
    import { useDataObjectEventListener } from 'o365-vue-utils';

    const session = getUserSession();
    const props = defineProps({
        dataObject: Object,
        className: {
            default: 'd-flex mt-2'
        },
        styleName: {
            default: ''
        },
        saveClass: {
            default: 'btn btn-outline-primary btn-sm ms-auto'
        },
        saveAsClass: {
            default: 'btn btn-outline-primary btn-sm ms-1'
        }
    });

    const filterObject = props.dataObject.filterObject;
    const dsFilters = props.dataObject.filterObject.filtersListDO;
    const showSaveAsDialog = ref(false);
    const shareWithModal = ref(null);
    const dsSharedWith = filterStore.getSharedWithDO();
    const newInput = ref(null);
    const isSaving = ref({
        newFilter : false,
        currentFilter:false
    });

    useDataObjectEventListener(dsSharedWith, 'BeforeSave', (pRow, pOptions) => {
        pRow.values.Filter_ID = dsSharedWith.fields.Filter_ID.defaultValue;
    });

    const saveFilterAs = async () => {
        modal.value.title="Save Filter As";
        modal.Name = null;
        showSaveAsDialog.value = true;
    }

    const shareWith = () =>{
        if(filterObject.activeFilter.ID){
            dsSharedWith.recordSource.whereClause = `[Filter_ID] = '${filterObject.activeFilter.ID}'`;
            dsSharedWith.fields.Filter_ID.defaultValue = filterObject.activeFilter.ID;
            dsSharedWith.load();
        }else{
        // dsSharedWith.clear
        }
        shareWithModal.value.show();
    }

    const saveFilter = () => {
        isSaving.value.currentFilter = true;
        dsFilters.current.FilterCriteria = filterObject.combinedFilterString;
        dsFilters.save().then(()=>{
            isSaving.value.currentFilter = false;
        });
        
    }

    async function saveNewFilter() {
        modal.value.title="Save new filter";
        modal.Name = null;
        showSaveAsDialog.value = true; 

        if(!newInput.value) await nextTick();
        newInput.value?.focus();
    }

    const modal = ref({
        title:"Save filter",
        Name:null,
        FilterCriteria:null
    });

    const doSaveNewFilter = () =>{
        var vItem = dsFilters.createNew();
        vItem.FilterName = modal.value.Name;
        vItem.FilterCriteria = filterObject.combinedFilterString;
        vItem.DBObjectID = props.dataObject.viewName;
        
        
        dsFilters.save().then((pRow) => {
            if (pRow.length > 0 && pRow[0].length > 0) {
                filterObject.applySavedFilter(pRow[0][0])
            }
            showSaveAsDialog.value = false;
        });
    }

    function handleShown(e) { 
  
        if(props.filterItem.id || props.filterItem.ID){
            dsSharedWith.recordSource.whereClause = `[Filter_ID] = '${props.filterItem.id??props.filterItem.ID}'`;
            dsSharedWith.fields.Filter_ID.defaultValue = props.filterItem.id??props.filterItem.ID;
            dsSharedWith.load();
        }

    }

    function getDisabledSaveTitle(item){
        if(isSaving.currentFilter){
            return $t('Saving')+'...';
        }

        if(item.activeFilter && !item.combinedFilterString){
            return $t('Filter is empty');
        }
         if(item.activeFilter && !item.activeFilter.Mine){
            return $t("Filter can be saved only by it's owner. Use Save As.");
        }
     
        if(!item.activeFilterHasChanges){
            return $t('Filter has no changes');
        }

         if(!item.combinedFilterString){
            return $t('Filter is empty');
        }
        if(item.activeFilter && item.activeFilter.Mine){
            return $t('Save current filter');
        }

        return $t('No filter to save');
    }
    function getDisabledSaveAsTitle(item){
        if(isSaving.currentFilter){
            return $t('Saving')+'...';
        }
        if(item.activeFilter || item.combinedFilterString){
            return $t('Save as / rename current filter');
        }
       
        return $t('No filter to save');
    }
    function getDisabledShareWithTitle(item){
        if(isSaving.currentFilter){
            return $t('Saving')+'...';
        }
        if(!item.activeFilter || !item.combinedFilterString){
            return $t('No filter to share');
        }
        if(item.activeFilter && !item.activeFilter.Mine){
            return $t('Only personal filters are allowed to be shared.');
        }

        return $t('Share filter with person');
    }

</script>

<style scoped>
    button:disabled {
        pointer-events: auto !important;
    }
</style>